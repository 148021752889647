import React, { createContext, useState, useContext, useCallback } from 'react';
import api from '../services/api';

const UserContext = createContext();

export function UserProvider({ children }) {
  const [userCredits, setUserCredits] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);

  const refreshUser = useCallback(async () => {
    try {
      const userData = await api.getCurrentUser();
      setCurrentUser(userData);
      setUserCredits(userData.credits);
      return userData;
    } catch (err) {
      console.error('Error fetching user data:', err);
      return null;
    }
  }, []);

  // Keeping refreshCredits for backward compatibility
  const refreshCredits = refreshUser;

  return (
    <UserContext.Provider value={{ 
      userCredits, 
      setUserCredits, 
      refreshCredits,
      currentUser,
      setCurrentUser,
      refreshUser
    }}>
      {children}
    </UserContext.Provider>
  );
}

export function useUser() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
} 