// ProfileCompletionForm.jsx
import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from './ui/card';
import { Label } from './ui/label';
import { Input } from './ui/input';
import { Textarea } from './ui/textarea';
import { Button } from './ui/button';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from './ui/select';

const ProfileCompletionForm = ({ initialData = {}, onSubmit }) => {
  const [formData, setFormData] = useState({
    user_type: '',
    user_type_other_description: '',
    school_name: '',
    subjects_taught: ''
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (initialData) {
      const subjects = Array.isArray(initialData.subjects_taught) 
        ? initialData.subjects_taught.join(', ')
        : initialData.subjects_taught || '';
      
      setFormData({
        user_type: initialData.user_type || '',
        user_type_other_description: initialData.user_type_other_description || '',
        school_name: initialData.school_name || '',
        subjects_taught: subjects
      });
    }
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    
    // Clear error when field is edited
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: null }));
    }
  };

  const handleSelectChange = (value) => {
    setFormData(prev => ({ ...prev, user_type: value }));
    
    // Clear error when field is edited
    if (errors.user_type) {
      setErrors(prev => ({ ...prev, user_type: null }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.user_type) {
      newErrors.user_type = 'Veuillez sélectionner votre type d\'utilisateur';
    }
    
    if (formData.user_type === 'autre' && !formData.user_type_other_description) {
      newErrors.user_type_other_description = 'Veuillez préciser votre type d\'utilisateur';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }
    
    setIsSubmitting(true);
    
    try {
      // Format subjects_taught as an array
      const submissionData = {
        ...formData,
        subjects_taught: formData.subjects_taught
          ? formData.subjects_taught.split(',').map(s => s.trim())
          : []
      };
      
      await onSubmit(submissionData);
    } catch (error) {
      setErrors({ submit: error.message || 'Une erreur est survenue. Veuillez réessayer.' });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Card className="w-full max-w-md mx-auto">
      <CardHeader>
        <CardTitle className="text-center">Compléter votre profil</CardTitle>
      </CardHeader>
      <CardContent>
        <form id="profile-form" onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="user_type">Type d'utilisateur*</Label>
            <Select 
              value={formData.user_type} 
              onValueChange={handleSelectChange}
              required
            >
              <SelectTrigger id="user_type">
                <SelectValue placeholder="Sélectionnez votre type d'utilisateur" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="enseignant">Enseignant</SelectItem>
                <SelectItem value="étudiant">Étudiant</SelectItem>
                <SelectItem value="chercheur">Chercheur</SelectItem>
                <SelectItem value="autre">Autre</SelectItem>
              </SelectContent>
            </Select>
            {errors.user_type && (
              <p className="text-sm text-red-500">{errors.user_type}</p>
            )}
          </div>

          {formData.user_type === 'autre' && (
            <div className="space-y-2">
              <Label htmlFor="user_type_other_description">Précisez votre type d'utilisateur*</Label>
              <Textarea
                id="user_type_other_description"
                name="user_type_other_description"
                value={formData.user_type_other_description}
                onChange={handleChange}
                placeholder="Décrivez votre rôle ou type d'utilisateur"
                className="resize-none"
                required={formData.user_type === 'autre'}
              />
              {errors.user_type_other_description && (
                <p className="text-sm text-red-500">{errors.user_type_other_description}</p>
              )}
            </div>
          )}

          <div className="space-y-2">
            <Label htmlFor="school_name">Nom de l'établissement</Label>
            <Input
              id="school_name"
              name="school_name"
              value={formData.school_name}
              onChange={handleChange}
              placeholder="Nom de votre établissement scolaire"
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="subjects_taught">Matières enseignées</Label>
            <Textarea
              id="subjects_taught"
              name="subjects_taught"
              value={formData.subjects_taught}
              onChange={handleChange}
              placeholder="Entrez les matières séparées par des virgules"
              className="resize-none"
            />
            <p className="text-xs text-gray-500">Séparez les matières par des virgules (ex: Mathématiques, Physique, Chimie)</p>
          </div>

          {errors.submit && (
            <div className="p-3 bg-red-100 border border-red-300 rounded text-red-500">
              {errors.submit}
            </div>
          )}
        </form>
      </CardContent>
      <CardFooter>
        <Button 
          type="submit" 
          form="profile-form"
          className="w-full" 
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Envoi en cours...' : 'Soumettre'}
        </Button>
      </CardFooter>
    </Card>
  );
};

export default ProfileCompletionForm;