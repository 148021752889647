// AccessDeniedPage.jsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardFooter } from '../components/ui/card';
import { Button } from '../components/ui/button';
import { Alert, AlertTitle, AlertDescription } from '../components/ui/alert';

const AccessDeniedPage = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  return (
    <div className="container mx-auto px-4 py-16 flex items-center justify-center">
      <Card className="max-w-md w-full">
        <CardContent className="pt-6">
          <div className="text-center mb-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="64"
              height="64"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="mx-auto text-red-500 mb-4"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <line x1="12" y1="8" x2="12" y2="12"></line>
              <line x1="12" y1="16" x2="12.01" y2="16"></line>
            </svg>
            <h2 className="text-2xl font-bold">Accès refusé</h2>
          </div>
          
          <Alert variant="destructive" className="mb-4">
            <AlertTitle>Votre compte n'est pas approuvé</AlertTitle>
            <AlertDescription>
              Votre compte n'a pas été approuvé ou a été désactivé.
            </AlertDescription>
          </Alert>
          
          <p className="text-center mb-4">
            Veuillez contacter l'administrateur si vous pensez qu'il s'agit d'une erreur.
          </p>
          <p className="text-center text-sm text-gray-500">
            Pour toute assistance, écrivez-nous à support@corrige.ca
          </p>
        </CardContent>
        <CardFooter className="flex justify-center pb-6">
          <Button variant="outline" onClick={handleLogout}>
            Retour à l'accueil
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
};

export default AccessDeniedPage;