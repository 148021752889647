import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card } from '../components/ui/card';
import { Button } from '../components/ui/button';
import Spinner from '../components/Spinner';
import api from '../services/api';
import { useUser } from '../context/UserContext';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";
import { Badge } from '../components/ui/badge';
import { AlertCircle, CheckCircle, XCircle, RefreshCw } from 'lucide-react';

const AdminPage = () => {
  const [pendingUsers, setPendingUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState(null);
  const [error, setError] = useState(null);
  const { currentUser } = useUser();
  const navigate = useNavigate();
  
  // Admin check
  const isAdmin = currentUser && currentUser.is_admin;
  
  useEffect(() => {
    // Redirect non-admin users
    if (currentUser && !isAdmin) {
      navigate('/assignments');
      return;
    }
    
    fetchPendingUsers();
  }, [currentUser, isAdmin, navigate]);
  
  const fetchPendingUsers = async () => {
    setLoading(true);
    setError(null);
    try {
      const data = await api.getPendingUsers();
      setPendingUsers(data);
    } catch (err) {
      console.error('Error fetching pending users:', err);
      setError('Erreur lors de la récupération des utilisateurs en attente');
    } finally {
      setLoading(false);
    }
  };
  
  const handleApprove = async (userId) => {
    setActionLoading(userId);
    try {
      await api.approveUser(userId);
      // Update the list to remove the approved user
      setPendingUsers(pendingUsers.filter(user => user.id !== userId));
    } catch (err) {
      console.error('Error approving user:', err);
      setError(`Erreur lors de l'approbation de l'utilisateur : ${err.message}`);
    } finally {
      setActionLoading(null);
    }
  };
  
  const handleReject = async (userId) => {
    setActionLoading(userId);
    try {
      await api.rejectUser(userId);
      // Update the list to remove the rejected user
      setPendingUsers(pendingUsers.filter(user => user.id !== userId));
    } catch (err) {
      console.error('Error rejecting user:', err);
      setError(`Erreur lors du rejet de l'utilisateur : ${err.message}`);
    } finally {
      setActionLoading(null);
    }
  };
  
  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Spinner />
      </div>
    );
  }
  
  return (
    <div className="container mx-auto px-4 py-8">
      <Card className="p-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold">Administration des comptes</h1>
          <Button 
            variant="outline" 
            onClick={fetchPendingUsers}
            className="flex items-center gap-2"
          >
            <RefreshCw size={16} />
            Actualiser
          </Button>
        </div>
        
        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4 flex items-center">
            <AlertCircle className="mr-2" size={20} />
            <span>{error}</span>
          </div>
        )}
        
        <h2 className="text-xl font-semibold mb-4">Utilisateurs en attente d'approbation</h2>
        
        {pendingUsers.length === 0 ? (
          <div className="text-center py-8 text-gray-500">
            Aucun utilisateur en attente d'approbation
          </div>
        ) : (
          <Table>
            {/* Table heading */}
            <TableHeader>
              <TableRow>
                <TableHead>Nom</TableHead>
                <TableHead>Courriel</TableHead>
                <TableHead>Type d'utilisateur</TableHead>
                <TableHead>École</TableHead>
                <TableHead>Date d'inscription</TableHead>
                <TableHead>Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {pendingUsers.map((user) => (
                <TableRow key={user.id}>
                  <TableCell className="font-medium">{user.full_name || '-'}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    {user.user_type ? (
                      <Badge variant="outline">{user.user_type}</Badge>
                    ) : (
                      '-'
                    )}
                  </TableCell>
                  <TableCell>{user.school_name || '-'}</TableCell>
                  <TableCell>{new Date(user.created_at).toLocaleDateString('fr-CA')}</TableCell>
                  <TableCell>
                    <div className="flex gap-2">
                      <Button
                        variant="outline"
                        size="sm"
                        className="text-green-600 hover:text-green-700 hover:bg-green-50 flex items-center gap-1"
                        onClick={() => handleApprove(user.id)}
                        disabled={actionLoading === user.id}
                      >
                        {actionLoading === user.id ? (
                          <Spinner size="sm" />
                        ) : (
                          <>
                            <CheckCircle size={16} />
                            Approuver
                          </>
                        )}
                      </Button>
                      <Button
                        variant="outline"
                        size="sm"
                        className="text-red-600 hover:text-red-700 hover:bg-red-50 flex items-center gap-1"
                        onClick={() => handleReject(user.id)}
                        disabled={actionLoading === user.id}
                      >
                        {actionLoading === user.id ? (
                          <Spinner size="sm" />
                        ) : (
                          <>
                            <XCircle size={16} />
                            Rejeter
                          </>
                        )}
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Card>
    </div>
  );
};

export default AdminPage;