// PublicRoute.jsx
import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import Spinner from '../Spinner';
import { useUser } from '../../context/UserContext';

function PublicRoute({ children }) {
  const [loading, setLoading] = useState(false);
  const { currentUser, refreshUser } = useUser();
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchUserData = async () => {
      if (!token) {
        return;
      }

      setLoading(true);
      try {
        // Use the context's refreshUser function
        await refreshUser();
      } catch (error) {
        console.error('Erreur lors de la récupération des données utilisateur:', error);
        localStorage.removeItem('token'); // Clear invalid token
      } finally {
        setLoading(false);
      }
    };

    // Only fetch if we don't already have user data and there's a token
    if (token && !currentUser) {
      fetchUserData();
    }
  }, [token, currentUser, refreshUser]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Spinner />
      </div>
    );
  }

  // If there's a token and we've loaded user data
  if (token && currentUser) {
    if (currentUser.account_status === 'approved') {
      return <Navigate to="/assignments" replace />;
    } else if (currentUser.account_status === 'pending') {
      // Check if profile is complete
      const isProfileIncomplete = !currentUser.user_type;
      
      if (isProfileIncomplete) {
        return <Navigate to="/complete-profile" replace />;
      } else {
        return <Navigate to="/pending-approval" replace />;
      }
    } else if (currentUser.account_status === 'rejected') {
      return <Navigate to="/access-denied" replace />;
    }
  }

  // Otherwise, allow them to see the children (e.g. the login/register page).
  return children;
}

export default PublicRoute;