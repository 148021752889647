// PendingApprovalPage.jsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardFooter } from '../components/ui/card';
import { Button } from '../components/ui/button';

const PendingApprovalPage = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  return (
    <div className="container mx-auto px-4 py-16 flex items-center justify-center">
      <Card className="max-w-md w-full">
        <CardContent className="pt-6">
          <div className="text-center mb-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="64"
              height="64"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="mx-auto text-amber-500 mb-4"
            >
              <path d="M14.5 4h-5L7 7H4a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2h-3l-2.5-3z"></path>
              <circle cx="12" cy="13" r="3"></circle>
            </svg>
            <h2 className="text-2xl font-bold">Compte en attente d'approbation</h2>
          </div>
          <p className="text-center mb-4">
            Merci pour votre inscription ! Votre compte est en attente d'approbation par un administrateur.
          </p>
          <p className="text-center mb-2">
            Vous recevrez un email lorsque votre accès sera activé.
          </p>
          <p className="text-center text-sm text-gray-500">
            Si vous n'avez pas reçu d'email dans les 48 heures, veuillez vérifier votre dossier de spam 
            ou nous contacter à support@corrige.ca
          </p>
        </CardContent>
        <CardFooter className="flex justify-center pb-6">
          <Button variant="outline" onClick={handleLogout}>
            Se déconnecter
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
};

export default PendingApprovalPage;