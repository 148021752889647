// config.js

export const API_BASE_URL = import.meta.env.VITE_API_BASE_URL || 'http://localhost:8000';
export const API_VERSION = '/api/v1';

export const API_ENDPOINTS = {
  // Auth
  LOGIN: '/auth/login',
  REGISTER: '/auth/register',
  GOOGLE_AUTH: '/auth/google/token-verify',
  CURRENT_USER: '/auth/me',
  UPDATE_PROFILE: '/users/me/profile',

  // Google Classroom
  CLASSROOM_CONNECT: '/classroom/connect',
  CLASSROOM_COURSES: '/classroom/courses',
  CLASSROOM_ASSIGNMENTS: (courseId) => `/classroom/courses/${courseId}/assignments`,
  CLASSROOM_SUBMISSIONS: (courseId, assignmentId) => `/classroom/courses/${courseId}/assignments/${assignmentId}/submissions`,
  CLASSROOM_IMPORT_SUBMISSIONS: '/classroom/import-submissions',

  // Assignments endpoints
  ASSIGNMENTS: '/assignments',
  SUBMISSIONS: '/submissions',
  ASSIGNMENT_SUBMISSIONS: (assignmentId) => `/assignments/${assignmentId}/submissions`,
  
  // Admin endpoints
  ADMIN_PENDING_USERS: '/admin/users/pending',
  ADMIN_APPROVE_USER: (userId) => `/admin/users/${userId}/approve`,
  ADMIN_REJECT_USER: (userId) => `/admin/users/${userId}/reject`,
};