// CompleteProfilePage.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ProfileCompletionForm from '../components/ProfileCompletionForm';
import Spinner from '../components/Spinner';
import api from '../services/api';

const CompleteProfilePage = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await api.getCurrentUser();
        setUserData(response);
      } catch (error) {
        console.error('Erreur lors de la récupération des données utilisateur:', error);
        setError('Impossible de charger les informations de votre profil. Veuillez réessayer.');
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const handleSubmit = async (formData) => {
    try {
      await api.updateUserProfile(formData);
      toast.success('Profil mis à jour. En attente d\'approbation.');
      
      // Add a small delay to ensure toast displays before navigation
      setTimeout(() => {
        navigate('/pending-approval');
      }, 500);
    } catch (error) {
      console.error('Erreur lors de la mise à jour du profil:', error);
      toast.error(error.message || 'Une erreur est survenue. Veuillez réessayer.');
      throw error; // Re-throw to let the form handle the error
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Spinner />
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mx-auto px-4 py-8 max-w-md">
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <strong className="font-bold">Erreur: </strong>
          <span className="block sm:inline">{error}</span>
        </div>
      </div>
    );
  }

  // Render a basic form if ProfileCompletionForm is not loading
  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-xl mx-auto">
        <h1 className="text-2xl font-bold mb-6 text-center">Compléter votre profil</h1>
        <p className="mb-6 text-center text-gray-700">
          Merci de compléter votre profil pour finaliser votre inscription. 
          Ces informations nous aideront à mieux comprendre vos besoins et à améliorer notre service.
        </p>
        {userData ? (
          <ProfileCompletionForm 
            initialData={userData} 
            onSubmit={handleSubmit} 
          />
        ) : (
          <div className="bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-3 rounded relative" role="alert">
            <strong className="font-bold">Attention: </strong>
            <span className="block sm:inline">Impossible de charger les données utilisateur. Veuillez rafraîchir la page.</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default CompleteProfilePage;