// ProtectedRoute.jsx

import React, { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import Spinner from '../Spinner';
import api from '../../services/api';
import { useUser } from '../../context/UserContext';

function ProtectedRoute({ children }) {
  const [loading, setLoading] = useState(true);
  const { currentUser, setCurrentUser, refreshUser } = useUser();
  const token = localStorage.getItem('token');
  const location = useLocation();

  useEffect(() => {
    const fetchUserData = async () => {
      if (!token) {
        setLoading(false);
        return;
      }

      setLoading(true);
      try {
        // Use the context's refreshUser function to get and set user data
        const userData = await refreshUser();
        if (!userData) {
          throw new Error('Failed to fetch user data');
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des données utilisateur:', error);
        // Handle error - could redirect to login or show a toast message
        localStorage.removeItem('token'); // Clear invalid token
      } finally {
        setLoading(false);
      }
    };

    // Only fetch if we don't already have user data
    if (!currentUser) {
      fetchUserData();
    } else {
      setLoading(false);
    }
  }, [token, currentUser, refreshUser]);

  if (!token) {
    return <Navigate to="/login" replace />;
  }

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Spinner />
      </div>
    );
  }

  if (!currentUser) {
    return <Navigate to="/login" replace />;
  }

  // Allow rendering of specific pages regardless of status
  if (location.pathname === '/complete-profile' || 
      location.pathname === '/pending-approval' || 
      location.pathname === '/access-denied') {
    return children;
  }

  // Check account status
  if (currentUser.account_status === 'approved') {
    return children;
  } else if (currentUser.account_status === 'pending') {
    // Check if profile is complete
    const isProfileIncomplete = !currentUser.user_type;
    
    if (isProfileIncomplete) {
      return <Navigate to="/complete-profile" replace />;
    } else {
      return <Navigate to="/pending-approval" replace />;
    }
  } else if (currentUser.account_status === 'rejected') {
    return <Navigate to="/access-denied" replace />;
  }

  // Fallback for unexpected status
  return <Navigate to="/login" replace />;
}

export default ProtectedRoute;